function Datenschutz() {
    return (
        <div className="m-5 fw-normal fs-6">
            <h1>Datenschutz&shy;erkl&auml;rung</h1>
            <h2>1. Datenschutz auf einen Blick</h2>
            <h3>Allgemeine Hinweise</h3> <p>Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber,
            was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind
            alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen. Ausf&uuml;hrliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgef&uuml;hrten
            Datenschutzerkl&auml;rung.</p>
            <h3>Datenerfassung auf dieser Website</h3> <h4>Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser
            Website?</h4> <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen
            Kontaktdaten k&ouml;nnen Sie dem Abschnitt &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser
            Datenschutzerkl&auml;rung entnehmen.</p> <h4>Wie erfassen wir Ihre Daten?</h4> <p>Ihre Daten werden zum
            einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die
            Sie in ein Kontaktformular eingeben.</p> <p>Andere Daten werden automatisch oder nach Ihrer Einwilligung
            beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.&nbsp;B.
            Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
            automatisch, sobald Sie diese Website betreten.</p> <h4>Wof&uuml;r nutzen wir Ihre Daten?</h4> <p>Ein Teil
            der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gew&auml;hrleisten. Andere Daten
            k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet werden.</p> <h4>Welche Rechte haben Sie
            bez&uuml;glich Ihrer Daten?</h4> <p>Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber
            Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben
            au&szlig;erdem ein Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen. Wenn Sie eine
            Einwilligung zur Datenverarbeitung erteilt haben, k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die
            Zukunft widerrufen. Au&szlig;erdem haben Sie das Recht, unter bestimmten Umst&auml;nden die
            Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
            Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.</p> <p>Hierzu sowie zu weiteren Fragen
            zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit an uns wenden.</p>
            <h3>Analyse-Tools und Tools von Dritt&shy;anbietern</h3> <p>Beim Besuch dieser Website kann Ihr
            Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit sogenannten
            Analyseprogrammen.</p> <p>Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden
            Datenschutzerkl&auml;rung.</p>
            <h2>2. Hosting und Content Delivery Networks (CDN)</h2>
            <h3>Cloudflare</h3> <p>Wir nutzen den Service &bdquo;Cloudflare&ldquo;. Anbieter ist die Cloudflare Inc.,
            101 Townsend St., San Francisco, CA 94107, USA (im Folgenden &bdquo;Cloudflare&rdquo;).</p> <p>Cloudflare
            bietet ein weltweit verteiltes Content Delivery Network mit DNS an. Dabei wird technisch der
            Informationstransfer zwischen Ihrem Browser und unserer Website &uuml;ber das Netzwerk von Cloudflare
            geleitet. Das versetzt Cloudflare in die Lage, den Datenverkehr zwischen Ihrem Browser und unserer Website
            zu analysieren und als Filter zwischen unseren Servern und potenziell b&ouml;sartigem Datenverkehr aus dem
            Internet zu dienen. Hierbei kann Cloudflare auch Cookies oder sonstige Technologien zur Wiedererkennung von
            Internetnutzern einsetzen, die jedoch allein zum hier beschriebenen Zweck verwendet werden.</p> <p>Der
            Einsatz von Cloudflare beruht auf unserem berechtigten Interesse an einer m&ouml;glichst fehlerfreien und
            sicheren Bereitstellung unseres Webangebotes (Art. 6 Abs. 1 lit. f DSGVO).</p> <p>Die Daten&uuml;bertragung
            in die USA wird auf die Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden Sie
            hier: <a href="https://www.cloudflare.com/privacypolicy/" target="_blank"
                     rel="noopener noreferrer">https://www.cloudflare.com/privacypolicy/</a>.</p> <p>Weitere
            Informationen zum Thema Sicherheit und Datenschutz bei Cloudflare finden Sie hier: <a
                href="https://www.cloudflare.com/privacypolicy/" target="_blank"
                rel="noopener noreferrer">https://www.cloudflare.com/privacypolicy/</a>.</p>
            <p>Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy
                Framework&ldquo; (DPF). Der DPF ist ein &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den
                USA, der die Einhaltung europ&auml;ischer Datenschutzstandards bei Datenverarbeitungen in den USA
                gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
                Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem
                Link: <a
                    href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000GnZKAA0&status=Active"
                    target="_blank"
                    rel="noopener noreferrer">https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000GnZKAA0&status=Active</a>.
            </p>
            <h4>Auftragsverarbeitung</h4> <p>Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur Nutzung
            des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich
            vorgeschriebenen Vertrag, der gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer
            Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.</p>
            <h2>3. Allgemeine Hinweise und Pflicht&shy;informationen</h2>
            <h3>Datenschutz</h3> <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr
            ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerkl&auml;rung.</p> <p>Wenn Sie diese Website benutzen,
            werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
            pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende Datenschutzerkl&auml;rung erl&auml;utert,
            welche Daten wir erheben und wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem Zweck
            das geschieht.</p> <p>Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.&nbsp;B. bei der
            Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor
            dem Zugriff durch Dritte ist nicht m&ouml;glich.</p>
            <h3>Hinweis zur verantwortlichen Stelle</h3> <p>Die verantwortliche Stelle f&uuml;r die Datenverarbeitung
            auf dieser Website ist:</p> <p>Tölzer Straße VV GmbH &amp; Co.
            KG<br/>
            Eichenhof 1, 85406 Zolling</p>

            <p>Telefon: 0 8167 98790 00<br/>
                E-Mail: felix.blum@toelzervv.de</p>
            <p>Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit
                anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen,
                E-Mail-Adressen o. &Auml;.) entscheidet.</p>

            <h3>Speicherdauer</h3> <p>Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere Speicherdauer
            genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck f&uuml;r die Datenverarbeitung
            entf&auml;llt. Wenn Sie ein berechtigtes L&ouml;schersuchen geltend machen oder eine Einwilligung zur
            Datenverarbeitung widerrufen, werden Ihre Daten gel&ouml;scht, sofern wir keine anderen rechtlich
            zul&auml;ssigen Gr&uuml;nde f&uuml;r die Speicherung Ihrer personenbezogenen Daten haben (z.&nbsp;B. steuer-
            oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die L&ouml;schung nach Fortfall
            dieser Gr&uuml;nde.</p>
            <h3>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</h3> <p>Sofern Sie
            in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von
            Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9
            Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdr&uuml;cklichen Einwilligung in die &Uuml;bertragung
            personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung au&szlig;erdem auf Grundlage von Art.
            49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in
            Ihr Endger&auml;t (z.&nbsp;B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung
            zus&auml;tzlich auf Grundlage von &sect; 25 Abs. 1 TDDDG. Die Einwilligung ist jederzeit widerrufbar. Sind
            Ihre Daten zur Vertragserf&uuml;llung oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen
            erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren
            verarbeiten wir Ihre Daten, sofern diese zur Erf&uuml;llung einer rechtlichen Verpflichtung erforderlich
            sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres
            berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. &Uuml;ber die jeweils im Einzelfall
            einschl&auml;gigen Rechtsgrundlagen wird in den folgenden Abs&auml;tzen dieser Datenschutzerkl&auml;rung
            informiert.</p>
            <h3>Empfänger von personenbezogenen Daten</h3> <p>Im Rahmen unserer Gesch&auml;ftst&auml;tigkeit arbeiten
            wir mit verschiedenen externen Stellen zusammen. Dabei ist teilweise auch eine &Uuml;bermittlung von
            personenbezogenen Daten an diese externen Stellen erforderlich. Wir geben personenbezogene Daten nur dann an
            externe Stellen weiter, wenn dies im Rahmen einer Vertragserf&uuml;llung erforderlich ist, wenn wir
            gesetzlich hierzu verpflichtet sind (z.&nbsp;B. Weitergabe von Daten an Steuerbeh&ouml;rden), wenn wir ein
            berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO an der Weitergabe haben oder wenn eine sonstige
            Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz von Auftragsverarbeitern geben wir
            personenbezogene Daten unserer Kunden nur auf Grundlage eines g&uuml;ltigen Vertrags &uuml;ber
            Auftragsverarbeitung weiter. Im Falle einer gemeinsamen Verarbeitung wird ein Vertrag &uuml;ber gemeinsame
            Verarbeitung geschlossen.</p>
            <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3> <p>Viele Datenverarbeitungsvorg&auml;nge sind nur
            mit Ihrer ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine bereits erteilte Einwilligung
            jederzeit widerrufen. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt
            vom Widerruf unber&uuml;hrt.</p>
            <p>Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, haben Sie
                jederzeit das
                Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung Ihrer
                personenbezogenen Daten Widerspruch einzulegen; dies gilt auch f&uuml;r ein auf diese Bestimmungen
                gest&uuml;tztes Profiling. Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht, entnehmen
                Sie
                dieser Datenschutzerkl&auml;rung. Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen
                personenbezogenen
                Daten nicht mehr verarbeiten, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde
                f&uuml;r die
                Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten &uuml;berwiegen oder die
                Verarbeitung dient
                der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen (Widerspruch nach Art. 21
                Abs. 1
                DSGVO).</p>
            <p>Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht,
                jederzeit
                Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger
                Werbung
                einzulegen; dies gilt auch f&uuml;r das Profiling, soweit es mit solcher Direktwerbung in Verbindung
                steht. Wenn
                Sie widersprechen, werden Ihre personenbezogenen Daten anschlie&szlig;end nicht mehr zum Zwecke der
                Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2 DSGVO).</p>
            <h3>Beschwerde&shy;recht bei der zust&auml;ndigen Aufsichts&shy;beh&ouml;rde</h3>
            <p>Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
                Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres
                Arbeitsplatzes oder des Orts des mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht besteht
                unbeschadet
                anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</p>
            <h3>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</h3>
            <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf&uuml;llung eines
                Vertrags
                automatisiert verarbeiten, an sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren
                Format
                aush&auml;ndigen zu lassen. Sofern Sie die direkte &Uuml;bertragung der Daten an einen anderen
                Verantwortlichen
                verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
            <h3>Auskunft, Berichtigung und L&ouml;schung</h3>
            <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
                Auskunft &uuml;ber Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den
                Zweck
                der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder L&ouml;schung dieser Daten. Hierzu sowie
                zu
                weiteren Fragen zum Thema personenbezogene Daten k&ouml;nnen Sie sich jederzeit an uns wenden.</p>
            <h3>Recht auf Einschr&auml;nkung der Verarbeitung</h3>
            <p>Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                Hierzu
                k&ouml;nnen Sie sich jederzeit an uns wenden. Das Recht auf Einschr&auml;nkung der Verarbeitung besteht
                in
                folgenden F&auml;llen:</p>
            <ul>
                <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten,
                    ben&ouml;tigen wir
                    in der Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der Pr&uuml;fung haben Sie
                    das Recht,
                    die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                </li>
                <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig geschah/geschieht,
                    k&ouml;nnen
                    Sie statt der L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung verlangen.
                </li>
                <li>Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie jedoch zur Aus&uuml;bung,
                    Verteidigung oder Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht,
                    statt der
                    L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                </li>
                <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abw&auml;gung
                    zwischen Ihren
                    und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen
                    Interessen &uuml;berwiegen,
                    haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu
                    verlangen.
                </li>
            </ul>
            <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben, d&uuml;rfen diese
                Daten &ndash; von ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur
                Geltendmachung,
                Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
                nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen
                Interesses der
                Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet werden.</p>
            <h3>SSL- bzw. TLS-Verschl&uuml;sselung</h3>
            <p>Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung vertraulicher Inhalte,
                wie zum
                Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
                TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen Sie daran, dass die Adresszeile
                des
                Browsers von &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in
                Ihrer
                Browserzeile.</p>
            <p>Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an
                uns &uuml;bermitteln, nicht von Dritten mitgelesen werden.</p>
            <h2>4. Datenerfassung auf dieser Website</h2>
            <h3>Hubspot CRM</h3>
            <p>Wir nutzen Hubspot CRM auf dieser Website. Anbieter ist Hubspot Inc. 25 Street, Cambridge, MA 02141 USA
                (nachfolgend Hubspot CRM).</p>
            <p>Hubspot CRM erm&ouml;glicht es uns unter anderem, bestehende und potenzielle Kunden sowie Kundenkontakte
                zu
                verwalten. Mit Hilfe von Hubspot CRM sind wir in der Lage, Kundeninteraktionen per E-Mail, Social Media
                oder
                Telefon &uuml;ber verschiedene Kan&auml;le hinweg zu erfassen, zu sortieren und zu analysieren. Die so
                erfassten
                personenbezogenen Daten k&ouml;nnen ausgewertet und f&uuml;r die Kommunikation mit dem potenziellen
                Kunden oder
                f&uuml;r Marketingma&szlig;nahmen (z.&nbsp;B. Newslettermailings) verwendet werden. Mit Hubspot CRM sind
                wir
                ferner in der Lage, das Nutzerverhalten unserer Kontakte auf unserer Website zu erfassen und zu
                analysieren.</p>
            <p>Die Verwendung von Hubspot CRM erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber
                hat ein
                berechtigtes Interesse an einer m&ouml;glichst effizienten Kundenverwaltung und Kundenkommunikation.
                Sofern eine
                entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage
                von Art.
                6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von Cookies
                oder den
                Zugriff auf Informationen im Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des
                TDDDG
                umfasst. Die Einwilligung ist jederzeit widerrufbar.</p>
            <p>Details entnehmen Sie der Datenschutzerkl&auml;rung von Hubspot: <a
                href="https://legal.hubspot.com/de/privacy-policy" target="_blank"
                rel="noopener noreferrer">https://legal.hubspot.com/de/privacy-policy</a>.</p>
            <p>Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission
                gest&uuml;tzt.
                Details finden Sie hier: <a href="https://www.hubspot.de/data-privacy/privacy-shield" target="_blank"
                                            rel="noopener noreferrer">https://www.hubspot.de/data-privacy/privacy-shield</a>.
            </p>
            <p>Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy
                Framework&ldquo; (DPF). Der DPF ist ein &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den
                USA, der
                die Einhaltung europ&auml;ischer Datenschutzstandards bei Datenverarbeitungen in den USA
                gew&auml;hrleisten
                soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards
                einzuhalten.
                Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link: <a
                    href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TN8pAAG&status=Active"
                    target="_blank"
                    rel="noopener noreferrer">https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TN8pAAG&status=Active</a>.
            </p>
            <h4>Auftragsverarbeitung</h4>
            <p>Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes
                geschlossen.
                Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gew&auml;hrleistet,
                dass
                dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter
                Einhaltung der
                DSGVO verarbeitet.</p>
            <h2>5. Analyse-Tools und Werbung</h2>
            <h3>Matomo</h3>
            <p>Diese Website benutzt den Open Source Webanalysedienst Matomo.</p>
            <p>Mit Hilfe von Matomo sind wir in der Lage Daten &uuml;ber die Nutzung unserer Website durch die
                Websitebesucher
                zu erfassen und zu analysieren. Hierdurch k&ouml;nnen wir u.&nbsp;a. herausfinden, wann welche
                Seitenaufrufe
                get&auml;tigt wurden und aus welcher Region sie kommen. Au&szlig;erdem erfassen wir verschiedene
                Logdateien
                (z.&nbsp;B. IP-Adresse, Referrer, verwendete Browser und Betriebssysteme) und k&ouml;nnen messen, ob
                unsere
                Websitebesucher bestimmte Aktionen durchf&uuml;hren (z.&nbsp;B. Klicks, K&auml;ufe u. &Auml;.).</p>
            <p>Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                Websitebetreiber hat
                ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine
                Werbung
                zu optimieren. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
                ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TDDDG, soweit die
                Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endger&auml;t des Nutzers
                (z.&nbsp;B. Device-Fingerprinting) im Sinne des TDDDG umfasst. Die Einwilligung ist jederzeit
                widerrufbar.</p>
            <h4>IP-Anonymisierung</h4>
            <p>Bei der Analyse mit Matomo setzen wir IP-Anonymisierung ein. Hierbei wird Ihre IP-Adresse vor der Analyse
                gek&uuml;rzt, sodass Sie Ihnen nicht mehr eindeutig zuordenbar ist.</p>
            <h4>Cookielose Analyse</h4>
            <p>Wir haben Matomo so konfiguriert, dass Matomo keine Cookies in Ihrem Browser speichert.</p>
            <h4>Hosting</h4>
            <p>Wir hosten Matomo ausschlie&szlig;lich auf unseren eigenen Servern, sodass alle Analysedaten bei uns
                verbleiben
                und nicht weitergegeben werden.</p>
        </div>
    );
}

export default Datenschutz;
